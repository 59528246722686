import React, { useState } from "react";
import { toast } from "react-toastify";
const LeaveInfo = () => {
  const [formValues, setFormValues] = useState({ email: "", name: "" });

  const handleSubmit = (e) => {
    e.preventDefault();
    const promise = new Promise((resolve, reject) =>
      fetch("/api/mail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      }).then((res) => {
        if (res.status === 201) {
          setFormValues({
            name: "",
            email: "",
          });
          return resolve(res);
        } else {
          return reject(res);
        }
      })
    );

    toast.promise(promise, {
      pending: "Email verzenden...",
      success: "Email succesvol verzonden!",
      error: "Er is een fout opgetreden, probeer het later opnieuw!",
    });
  };

  return (
    <>
      <section className="py-20 bg-top bg-no-repeat leave-background">
        <div className="container px-4 mx-auto">
          <div className="relative py-20 px-4 lg:p-20">
            <div className="max-w-2xl mx-auto text-center">
              <h2 className="mb-4 text-3xl lg:text-4xl font-bold font-heading  animate__animated animate__fadeIn">
                <span>Laat uw gegevens achter.</span>
                <br />
                <span className="text-blue-500">
                  Wij nemen contact met u op!
                </span>
              </h2>
              <p
                className="mb-8 text-blueGray-400  animate__animated animate__fadeIn"
                data-wow-delay=".1s"
              >
                Al uw informatie is volledig vertrouwelijk
              </p>
              <div
                className="p-4 bg-white rounded-lg flex flex-wrap max-w-md mx-auto  animate__animated animate__fadeIn"
                data-wow-delay=".1s"
              >
                <form onSubmit={handleSubmit} className="w-full">
                  <div className="flex w-full px-3 mb-3 md:mb-3 bg-blueGray-100 rounded">
                    <svg
                      className="h-6 w-6 my-auto text-blueGray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 18 14"
                      fill="currentColor"
                    >
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    </svg>
                    <input
                      required
                      value={formValues.name}
                      onChange={(event) =>
                        setFormValues({
                          ...formValues,
                          name: event.target.value,
                        })
                      }
                      className="w-full pl-3 py-4 text-xs text-blueGray-400 font-semibold leading-none bg-blueGray-100 outline-none"
                      type="text"
                      placeholder="Volledige naam"
                      name="name"
                      control-id="ControlID-1"
                    />
                  </div>
                  <div className="flex w-full px-3 mb-3 md:mb-3 bg-blueGray-100 rounded">
                    <svg
                      className="h-6 w-6 my-auto text-blueGray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 22 18"
                      fill="currentColor"
                    >
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                    </svg>
                    <input
                      required
                      value={formValues.email}
                      onChange={(event) =>
                        setFormValues({
                          ...formValues,
                          email: event.target.value,
                        })
                      }
                      className="w-full pl-3 py-4 text-xs text-blueGray-400 font-semibold leading-none bg-blueGray-100 outline-none"
                      type="email"
                      placeholder="E-mail"
                      name="email"
                      control-id="ControlID-2"
                    />
                  </div>
                  <button
                    className="w-full py-4 px-8 text-xs text-white font-semibold leading-none bg-blue-400 hover:bg-blue-500 rounded"
                    type="submit"
                    control-id="ControlID-3"
                  >
                    Versturen
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LeaveInfo;
