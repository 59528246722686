import Image from "next/image";
import SwiperCore, {Autoplay} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

SwiperCore.use([Autoplay]);

const Slider3 = () => {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={30}
                autoplay={{
                    delay: 6000,
                }}
                loop={true}
            >
                <SwiperSlide>
                    <Image
                        className="rounded cursor-pointer"
                        src="/assets/imgs/screenshots/cohesian.webp"
                        alt="cohesian.be"
                        width="871"
                        height="543"
                        priority={true}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <Image
                        className="rounded cursor-pointer"
                        src="/assets/imgs/screenshots/1.webp"
                        alt="rehablab.nl"
                        width="871"
                        height="543"
                        priority={true}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <Image
                        className="rounded cursor-pointer"
                        src="/assets/imgs/screenshots/2.webp"
                        alt="bestcoal.be"
                        width="871"
                        height="543"
                        priority={true}
                    />
                </SwiperSlide>
                <SwiperSlide>
                    <Image
                        className="rounded cursor-pointer"
                        src="/assets/imgs/screenshots/3.webp"
                        alt="venuskebab.be"
                        width="871"
                        height="543"
                        priority={true}
                    />
                </SwiperSlide>
            </Swiper>
        </>
    );
};

export default Slider3;
